@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'NarkissBlock Light';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Light.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Light.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Regular';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Regular.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Regular.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Medium';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Medium.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Medium.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Bold';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Bold.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Bold.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Semibold';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Semibold.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Semibold.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Black';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Black.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Black.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Black.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NarkissBlock Heavy';
	src: url('src/routes/client/assets/fonts/NarkissBlock-Heavy.woff2') format('woff2'),
		url('src/routes/client/assets/fonts/NarkissBlock-Heavy.woff') format('woff'),
		url('src/routes/client/assets/fonts/NarkissBlock-Heavy.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}