@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.loader {
  display: inline-block;
  position: relative;
  width: vw_d(64);
  height: vw_d(64);

  @include screen('mobile') {
    width: vw(64);
    height: vw(64);
  }

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: vw_d(56);
    height: vw_d(56);
    margin: vw_d(4);
    border: vw_d(4) solid #fff;
    border-radius: 50%;
    animation: anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(222, 26, 26) transparent transparent transparent;

    @include screen('mobile') {
      width: vw(56);
      height: vw(56);
      margin: vw(4);
      border: vw(4) solid #fff;
    }

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
