@import 'client/styles/functions.scss';

.google-ads {
	margin: 0 auto;
	display: block;
	background-color: white;

	& > div {
		display: flex!important;
		justify-content: center;
		align-items: center;
		height: inherit;
		margin: 0 auto;
	}

	iframe {
		// width: 100%;

		// & > div {
		// 	width: 100% !important;

		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	height: inherit;
		// 	width: 100%;
		// }

		img {
			width: 100% !important;
			height: auto !important;
		}
	}
}

.close-button {
  width: vw(30);
  height: vw(30);
  background: #fff;
  z-index: 10;
  position: absolute;
  top: vw(15);
  right: vw(10);
  border-radius: 100%;
  border: 1px solid #848484;

  svg {
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
