@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/theme.scss';
@import 'src/routes/client/styles/functions';

.ydj-select__control {
  border: vw_d(2) solid #fff !important;
  transition: 0.4s border-color;
  &:hover,
  &:focus-within {
    border: vw_d(2) solid #f3bcbc !important;
  }
}

.ydj-select__control--is-focused {
  border: vw_d(2) solid #f3bcbc !important;
  box-sizing: border-box;
}
.ydj-select__indicators {
  height: vw_d(42);
  width: vw_d(42);
  justify-content: center;
}
.ydj-select__placeholder,
.css-18jpjs4-placeholder {
  font-size: vw_d(18) !important;
  line-height: vw_d(24);
}


.css-zyqpqj-multiValue{
  margin-right: vw_d(35) !important;
}
