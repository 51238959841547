@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.container {
  position: relative;
  width: 100%;
  // min-height: calc(100vh - vw_d(519));
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding-bottom: vw_d(30);

  &-list {
    width: 96.7%;
    margin: vw_d(55) vw_d(20) 0;

    @include screen('mobile') {
      margin: vw(22) auto 0;
    }
  }

  .recommendations-title {
    font-size: vw_d(31);
    letter-spacing: vw_d(-0.6);
    font-family: $medium-font;
    color: #000;

    @include screen('mobile') {
      font-size: vw(24);
      letter-spacing: vw(-0.42);

      html[data-theme="dark"] & {
        color: #fff;
      }
    }
  }

  .empty-list-message {
    margin: 0 auto vw_d(37);
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    align-items: center;

    @include screen('mobile') {
      flex-direction: column;
      margin: 0 auto vw(42);
    }

    .icon {
      width: vw_d(65);
      // margin-left: vw_d(10);

      @include screen('mobile') {
        width: vw(65);
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      font-family: $regular-font;
      font-size: vw_d(31);
      letter-spacing: vw_d(-0.52);
      color: #000;
      padding: vw_d(7) vw_d(11) 0 0;

      @include screen('mobile') {
        width: vw(222);
        margin: 0 auto;
        padding: vw(11) 0 0;
        font-size: vw(24);
        line-height: 0.96;
        letter-spacing: -0.4px;
        text-align: center;

        html[data-theme="dark"] & {
          color: #fff;
        }
      }
    }
  }

  .loader {
    position: absolute;
    top: vw_d(20);
    left: 50%;
    transform: translate(-50%, 0);
  }

  &-header {
    margin-top: vw_d(23);
    font-size: vw_d(20);
    line-height: vw_d(26);
    letter-spacing: vw_d(-0.33);
    font-family: $regular-font;
    text-align: center;

    @include screen('mobile') {
      font-size: vw(14);
      line-height: vw(21);
      margin-top: vw(19);
      margin-bottom: vw(18);

      html[data-theme="dark"] & {
        color: #fff;
      }
    }
  }

  & .up-stage {
    position: fixed;
    bottom: vw_d(100);
    left: calc((100% - #{vw_d(1240)}) / 2 - (#{vw_d(30)}));
    transition: all 0.3s ease;
    border-radius: 50%;
    z-index: 3;
    width: vw_d(40);
    height: vw_d(40);
    background-color: white;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

    @include screen('mobile') {
      width: vw(40);
      height: vw(40);
      bottom: vw(20);
      left: vw(6);
      cursor: pointer;
    }

    & svg {
      height: vw_d(22);
      width: vw_d(24);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include screen('mobile') {
        height: vw(22);
        width: vw(24);
      }
    }
  }
}
