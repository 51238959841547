@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';

.container {
	position: relative;
	max-width: 1440px;
	padding: 0 40px;
	margin: 0 auto;

	@include screen('mobile') {
		padding: 0 15px;
	}
}
