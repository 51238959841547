@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.ef-select {
  position: relative;
  text-align: initial;

  .value-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
  }

  .value {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #545353;
  }

  .dropdown-wrap {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: fit-content;
    overflow-y: auto;
    background-color: white;
    scrollbar-width: none;
    cursor: pointer;
    z-index: 4;
    box-shadow: 0 0 vw_d(9) 0 rgba(57, 110, 228, 0.1);

    &::-webkit-scrollbar {
      width: vw_d(14) !important;

      @include screen('mobile') {
        width: vw(14) !important;
      }
    }

    &::-webkit-scrollbar-thumb {
      border: vw_d(4) solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #e6e6e6;

      @include screen('mobile') {
        border: vw(4) solid rgba(0, 0, 0, 0);
      }
    }

    .dropdown-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .dropdown-indicator {
    position: absolute;
    border-radius: 50%;
    cursor: pointer;

    i {
      display: block;
      transition: 0.3s;

      svg {
        display: block;
        width: 100% !important;
        height: 100% !important;
        border-radius: 50%;
      }
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  .option {
    cursor: default;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-family: $regular-font;
      letter-spacing: -0.3px;
      color: #3f3f3f;
    }
  }

  &:not(.is-iframe) {
    width: vw_d(200);
    height: vw_d(42);
  
    @include screen('mobile') {
      width: 100%;
      height: vw(42);
    }

    &:hover {
      .value-container {
        border: solid vw_d(2) var(--borderColor);
  
        @include screen('mobile') {
          border: solid vw(2) var(--borderColor);
        }
      }
    }
  
    &.opened {
      .value-container {
        border: solid vw_d(2) var(--iconsColor);
  
        @include screen('mobile') {
          border: solid vw(2) var(--iconsColor);
        }
      }
  
      .dropdown-indicator {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .value-container {
      padding: 0 vw_d(15) 0 vw_d(40);
      border-radius: vw_d(24);
      border: solid vw_d(2) transparent;
  
      @include screen('mobile') {
        padding: 0 vw(15) 0 vw(40);
        border-radius: vw(24);
        border: solid vw(2) transparent;
      }
    }

    .value {
      padding: vw_d(5) 0;
      font-size: vw_d(18);
  
      @include screen('mobile') {
        font-size: vw(18);
        padding: vw(5) 0;
        color: #000;
      }
    }

    .dropdown-wrap {
      transform: translate(0, vw_d(7));
      border-radius: vw_d(16);
      padding: vw_d(16) vw_d(12) vw_d(16) 0;
  
      @include screen('mobile') {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }
    }

    .dropdown-indicator {
      top: vw_d(5);
      left: vw_d(5);
      width: vw_d(28);
      height: vw_d(28);
  
      @include screen('mobile') {
        left: vw(5);
        top: vw(5);
        width: vw(28);
        height: vw(28);
      }
  
      i {
        width: vw_d(28);
        height: vw_d(28);
  
        @include screen('mobile') {
          width: vw(28);
          height: vw(28);
        }
      }
    }

    .dropdown {
      @include screen('mobile') {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: vw(16);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: vw(16) vw(20) vw(30);
        background-color: white;
        z-index: 2;
      }
    }

    .option {
      margin-bottom: vw_d(10);
  
      &:last-child {
        margin-bottom: 0;
      }
  
      @include screen('mobile') {
        margin-bottom: vw(20);
      }
  
      p {
        font-size: vw_d(18);
  
        @include screen('mobile') {
          font-size: vw(18);
        }
      }
    }
  }


  .dd-title {
    font-size: vw(20);
    padding-bottom: vw(30);
    text-align: center;
    color: #000;
    font-family: $medium-font;
  }

  .action-buttons {
    display: flex;
    flex-flow: row nowrap;
    padding-top: vw(30);

    .approve-btn {
      width: vw(232);
      font-family: $medium-font;
    }

    .cancel-btn {
      width: vw(107);
      background-color: transparent;
      text-decoration: underline;
      color: #000;
      font-family: $regular-font;
      font-size: vw(18);
    }
  }

  &.is-iframe {
    width: 29%;
    height: 42px;

    &.is-real-mobile {
      width: 100%;

      .dropdown-wrap {
        position: fixed;
        top: -5px;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }

      .dropdown {
        min-height: fit-content;
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 8px;
        width: calc(100% - 16px);
        border-radius: 30px;
        padding: 16px 10px 20px 20px;
        background-color: white;
        transform: translateY(-50%);
        z-index: 2;
      }

      .close-button {
        position: absolute;
        top: 17px;
        left: 18px;
      }

      .action-buttons {
        .approve-btn {
          width: 260px;
          height: 45px;
        }
      }

      .options-list {
        padding-right: 10px;
      }
    }

    &:hover {
      .value-container {
        border: solid 2px var(--borderColor);
      }
    }
  
    &.opened {
      .value-container {
        border: solid 2px var(--iconsColor);
      }
  
      .dropdown-indicator {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .value-container {
      border-radius: 24px;
      padding: 0 15px 0 40px;
      border: solid 2px transparent;
    }

    .value {
      padding: 5px 0;
      font-size: 18px;
    }

    .dropdown-wrap {
      width: auto;
      padding: 16px 21px 19px 22px;
      border-radius: 16px;
      transform: translate(0, 5px);

      &.small-spaces {
        padding: 10px 13px 8px 13px;

        .options-list {
          grid-row-gap: 5px;
        }
      }

      &.right {
        right: 0;
        left: auto;
      }
      &.left {
        left: 0;
      }
      &.center {
        left: 50%;
        transform: translate(-50%, 5px);
      }
    }

    .options-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 13px;
      grid-auto-flow: column;
    }

    .dropdown-indicator {
      top: 5px;
      left: 5px;
      width: 28px;
      height: 28px;

      i {
        width: 28px;
        height: 28px;
      }
    }

    .option {
      align-items: center;
      white-space: nowrap;
      margin-bottom: 0;

      p {
        font-size: 18px;
      }

      & > div {
        width: 20px;
        height: 20px;
      }
    }
  }
}
