@import 'src/routes/client/styles/index.scss';

.datepicker-component {
  position: relative;

  &:after {
    content: '';
    width: vw_d(240);
    height: vw_d(42);
    border-radius: vw_d(24);
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    border: vw_d(2) solid #fff;

    @include screen('tablet'){
      width: 100%;
      height: vw(42);
      border-radius: vw(24);
    }
    @include screen('mobile'){
      width: 100%;
      height: vw(42);
      border-radius: vw(24);
    }
  }

  &:hover {
    &:after {
      border: vw_d(2) solid var(--borderColor);
    }
  }

  &.is-iframe {
    &.is-real-mobile {
      &:after {
        width: 100%;
      }
      .datepicker-input {
        width: 100%;
      }

      .dropdown-bg {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .close-icon {
        position: fixed;
        top: 65px;
        left: calc(((100vw - 358px) / 2) + 13px);
        z-index: 100000;
      }
    }

    &:after {
      width: 100%;
      height: 42px;
      border-radius: 24px;
    }

    &:hover {
      &:after {
        border: 2px solid var(--borderColor);
      }
    }

    .datepicker-input {
      width: 100%;
      height: 42px;
      font-size: 18px;
      padding: 7px 15px 7px 35px;
      border-radius: 24px;
      color: #545353;
      border: 2px solid #fff;
      
      &::-webkit-input-placeholder {
        color: #545353;
      }
      &::-moz-placeholder {
        color: #545353;
      }
      &::-ms-input-placeholder {
        color: #545353;
      }
      &::placeholder {
        color: #545353;
      }
    }

    &.isOpened {
      &:after {
        border: 2px solid var(--iconsColor);
      }
    }
  }

  &:not(.is-iframe) {
    .datepicker-input {
      width: vw_d(240);
      height: vw_d(42);
      padding: vw_d(7) vw_d(15) vw_d(7) vw_d(35);
      border: vw_d(2) solid #fff;
      border-radius: vw_d(24);
      background-color: #fff;
      font-size: vw_d(18);
      opacity: 1;
      cursor: pointer;
  
      @include screen('tablet'){
        width: 100%;
        height: vw(42);
        font-size: vw(18);
        padding: vw(7) vw(15) vw(7) vw(35);
        border: vw(2) solid #fff;
        border-radius: vw(24);
        font-size: vw(18);
      }
      @include screen('mobile'){
        width: 100%;
        height: vw(42);
        font-size: vw(18);
        padding: vw(7) vw(15) vw(7) vw(35);
        border: vw(2) solid #fff;
        border-radius: vw(24);
        font-size: vw(18);
      }
    }
    &.isOpened {
      &:after {
        border: vw_d(2) solid var(--iconsColor);
  
        @include screen('tablet'){
          border: vw(2) solid var(--iconsColor);
        }
        @include screen('mobile'){
          border: vw(2) solid var(--iconsColor);
        }
      }
    }
  }

  .datepicker-input {
    width: vw_d(240);
    height: vw_d(42);
    padding: vw_d(7) vw_d(15) vw_d(7) vw_d(35);
    border: vw_d(2) solid #fff;
    border-radius: vw_d(24);
    background-color: #fff;
    font-size: vw_d(18);
    opacity: 1;
    cursor: pointer;

    &::-webkit-input-placeholder {
      color: #545353;
      opacity: 1;
      @include screen('tablet'){
        color: #000;
      }
      @include screen('mobile'){
        color: #000;
      }
    }
    &::-moz-placeholder {
      color: #545353;
      opacity: 1;
      @include screen('tablet'){
        color: #000;
      }
      @include screen('mobile'){
        color: #000;
      }
    }
    &::-ms-input-placeholder {
      color: #545353;
      opacity: 1;
      @include screen('tablet'){
        color: #000;
      }
      @include screen('mobile'){
        color: #000;
      }
    }
    &::placeholder {
      color: #545353;
      opacity: 1;
      @include screen('tablet'){
        color: #000;
      }
      @include screen('mobile'){
        color: #000;
      }
    }
  }
  
  &.isOpened {
    &:after {
      border: vw_d(2) solid var(--iconsColor);
    }

    .datepicker-icon {
      @include screen('mobile'){
        transform: rotate(180deg);
      }
    }
  }
}

// mobiscroll styles
.datepicker {
  font-family: $regular-font;
  font-size: vw_d(18);

  &:not(.is-iframe) {
    @include screen('tablet'){
      font-size: vw(18);
      width: calc(100vw - #{vw(20)});
    }
    @include screen('mobile'){
      font-size: vw(18);
      width: calc(100vw - #{vw(20)});
    }

    :global {
      .mbsc-datepicker .mbsc-calendar {
        width: vw_d(339)!important;
  
        @include screen('tablet'){
          width: calc(100vw - #{vw(20)})!important;
        }
        @include screen('mobile'){
          width: calc(100vw - #{vw(20)})!important;
        }
      }
  
      .mbsc-popup {
        @include screen('tablet'){
          left: vw(10)!important;
          right: vw(10);
        }
        @include screen('mobile'){
          left: vw(10)!important;
          right: vw(10);
        }
      }
  
      .mbsc-datepicker .mbsc-calendar-slide {
        @include screen('tablet'){
          padding: 0 vw(13) vw(6);
        }
        @include screen('mobile'){
          padding: 0 vw(13) vw(6);
        }
      }
  
      .mbsc-font{
        font-size: vw_d(18);
  
        @include screen('tablet'){
          font-size: vw(18);
        }
        @include screen('mobile'){
          font-size: vw(18);
        }
      }
  
      .mbsc-popup-body {
        border-radius: vw_d(16);
        box-shadow: 0 0 9px 0 rgba(57, 110, 228, 0.1);
  
        @include screen('tablet'){
          border-radius: vw(16);
          width: calc(100vw - #{vw(20)});
        }
        @include screen('mobile'){
          border-radius: vw(16);
          width: calc(100vw - #{vw(20)});
        }
      }
  
      .mbsc-calendar-controls, 
      .mbsc-calendar-header .mbsc-button {
        justify-content: center;
        padding-bottom: 0;
  
        @include screen('tablet'){
          padding: vw(20) 0 0;
        }
        @include screen('mobile'){
          padding: vw(20) 0 0;
        }
      }
  
      .mbsc-calendar-header .mbsc-button {
        padding: vw_d(4) 0 vw_d(6);
        font-family: $semibold-font;
        font-size: vw_d(20);
        line-height: 1.3;
        letter-spacing: -0.33px;
        color: #030303;
  
        @include screen('tablet'){
          padding: vw(4) 0 vw(6);
          font-size: vw(18);
        }
        @include screen('mobile'){
          padding: vw(4) 0 vw(6);
          font-size: vw(18);
        }
      }

      .mbsc-calendar-title,
      .mbc-button {
        font-family: $semibold-font;
        font-size: vw_d(20);
        line-height: 1.3;
        letter-spacing: -0.33px;
        color: #030303;

        @include screen('tablet'){
          font-size: vw(20);
        }
        @include screen('mobile'){
          font-size: vw(20);
        }
      }

      .mbsc-calendar-week-day {
        font-family: $bold-font;
        height: vw_d(40);
        line-height: vw_d(40);
        font-size: vw_d(18);
        color: #000;

        @include screen('tablet'){
          height: vw(40);
          line-height: vw(40);
          font-size: vw(18);
        }
        @include screen('mobile'){
          height: vw(40);
          line-height: vw(40);
          font-size: vw(18);
        }
      }

      .mbsc-calendar-day-text {
        color: #000;
        font-size: vw_d(14);
        line-height: vw_d(34);
        width: vw_d(34);
        height: vw_d(34);
        margin:0 auto;

        @include screen('tablet'){
          width: vw(34);
          height: vw(34);
          line-height: vw(34);
          font-size: vw(14);
        }
        @include screen('mobile'){
          width: vw(34);
          height: vw(34);
          line-height: vw(34);
          font-size: vw(14);
        }
      }

      .mbsc-selected .mbsc-calendar-day-text,
      .mbsc-hover .mbsc-calendar-day-text{
        font-size: vw_d(14)!important;
        line-height: vw_d(34)!important;
        width: vw_d(34)!important;
        height: vw_d(34)!important;
        margin: 0 auto!important;
        color: white;
        background: var(--iconsColor);
        border-color: var(--iconsColor);

        @include screen('tablet'){
          width: vw(34)!important;
          height: vw(34)!important;
          line-height: vw(34)!important;
          font-size: vw(14)!important;
        }
        @include screen('mobile'){
          width: vw(34)!important;
          height: vw(34)!important;
          line-height: vw(34)!important;
          font-size: vw(14)!important;
        }
      }
    }
  }

  &.is-iframe {
    font-size: 18px;

    :global {
      .mbsc-datepicker .mbsc-calendar {
        padding-bottom: 5px;
      }

      .mbsc-popup {
        top: 6px !important;
        left: 50% !important;
        max-width: 300px !important;
        max-height: 260px !important;
        transform: translate(-50%, 0);
      }

      .mbsc-font{
        font-size: 18px;
      }

      .mbsc-popup-body {
        border-radius: 16px;
        box-shadow: 0 0 9px 0 rgba(57, 110, 228, 0.1);
      }

      .mbsc-calendar-controls, 
      .mbsc-calendar-header .mbsc-button {
        justify-content: center;
        padding: 7px 0 0;
        max-height: 30px;
        min-height: 30px;
      }

      .mbsc-calendar-header .mbsc-button {
        padding: 0;
        font-family: $semibold-font;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: -0.33px;
        color: #030303;
      }

      .mbsc-calendar-title,
      .mbc-button {
        font-family: $semibold-font;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: -0.33px;
        color: #030303;
      }

      .mbsc-calendar-week-day {
        font-family: $bold-font;
        height: 25px;
        line-height: 25px;
        font-size: 16px;
        color: #000;
      }

      .mbsc-calendar-day-text {
        color: #000;
        font-size: 12px;
        line-height: 25px;
        width: 25px;
        height: 25px;
        margin:0 auto;
      }

      .mbsc-selected .mbsc-calendar-day-text,
      .mbsc-hover .mbsc-calendar-day-text{
        font-size: 12px !important;
        line-height: 25px !important;
        width: 25px!important;
        height: 25px!important;
        margin: 0 auto!important;
        color: white;
        background: var(--iconsColor);
        border-color: var(--iconsColor);
      }

      .mbsc-material.mbsc-textfield-wrapper {
        border: 2px solid #fff;
        border-radius: 24px;
        background-color: #fff;
        font-size: 18px;
      }
    }

    &.is-real-mobile {
      :global {
        .mbsc-datepicker .mbsc-calendar {
          width: calc(100vw - 16px);
        }

        .mbsc-popup {
          width: 100%;
          left: auto !important;
          top: 50px !important;
          max-width: 358px !important;
          max-height: 320px !important;
          transform: none;
        }

        .mbsc-calendar-controls, 
        .mbsc-calendar-header .mbsc-button {
          padding: 6px 0 0px 0;
          max-height: 37px;
          min-height: 37px;
        }

        .mbsc-calendar-week-day {
          font-family: $bold-font;
          height: 34px;
          line-height: 34px;
          font-size: 18px;
        }
  
        .mbsc-calendar-day-text {
          font-family: $regular-font;
          font-size: 18px;
          line-height: 33px;
          width: 33px;
          height: 33px;
          margin:0 auto;
        }

        .mbsc-selected .mbsc-calendar-day-text,
        .mbsc-hover .mbsc-calendar-day-text{
          font-size: 18px !important;
          line-height: 33px !important;
          width: 33px!important;
          height: 33px!important;
        }

        .mbsc-datepicker .mbsc-calendar-slide {
          padding: 0 8px 0 15px;
        }
      }
    }
  }

  :global {
    .mbsc-calendar-header .mbsc-button {
      display: flex;
      flex-direction: row-reverse;
    }

    .custom-nav {
      pointer-events: none;
    }

    .custom-nav .mbsc-icon{
      display: none;
    }

    .mbsc-calendar-day:after,
    .mbsc-range-hover::before{
      height: 86%;
      top: 7%;
      border: none;
      background-color: var(--iconsColor);
      opacity: 0.2;
    }

    .mbsc-icon > svg {
      fill: var(--iconsColor);
    }

    .mbsc-hover .mbsc-calendar-cell-text{
      background-color: var(--iconsColor);
    }

    .mbsc-disabled .mbsc-calendar-cell-text {
      color: #9b9b9b;
      opacity: 1;
    }

    // input styles
    .mbsc-material.mbsc-textfield-wrapper {
      width: vw_d(240);
      height: vw_d(42);
      padding: vw_d(7) vw_d(15) vw_d(7) vw_d(7);
      border: vw_d(2) solid #fff;
      border-radius: vw_d(24);
      background-color: #fff;
      font-size: vw_d(18);
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      @include screen('tablet'){
        margin-right: vw(8);
        margin-top: vw(12);
        border-radius: vw(24);
        width: vw(359);
        height: vw(42);
        font-size: vw(18);
      }
      @include screen('mobile'){
        margin-right: vw(8);
        margin-top: vw(12);
        border-radius: vw(24);
        width: vw(359);
        height: vw(42);
        font-size: vw(18);
      }
    }

    .mbsc-material.mbsc-textfield-box.mbsc-select,
    .mbsc-material.mbsc-textfield-inner-box{
      background: white;
    }
  }
}

.datepicker-icon {
  position: absolute;
  left: vw_d(6);
  top: vw_d(7);
  width: vw_d(28);
  height: vw_d(28);
  pointer-events: none;
  transition: 0.3s;

  @include screen('tablet'){
    height: vw(28);
    width: vw(28);
    top: vw(6);
    left: vw(7);
  }
  @include screen('mobile'){
    height: vw(28);
    width: vw(28);
    top: vw(6);
    left: vw(7);
  }

  &.is-iframe {
    left: 6px;
    top: 7px;
    width: 28px;
    height: 28px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
