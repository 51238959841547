@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';
@import 'client/styles/functions.scss';

.layout {
  max-width: vw_d(1240);
  margin: 0 auto;
  min-height: 110vh;

  @include screen('tablet') {
    max-width: 100%;
  }
  @include screen('mobile') {
    max-width: 100%;
  }
}

.main {
  position: relative;
}

:global {
  #doublejumbocont {
    position: absolute;
    top: 0;
    height: inherit;
    width: 100%;
  }
}

.google-ads {
  &-top-banner {
    width: auto!important;
    background-color: white!important;
    padding: 5px 0!important;

    @include screen('mobile') {
      padding: 0!important;
    }

    & > div {
      display: flex!important;
      justify-content: center;
      align-items: center;
      height: inherit;
      margin: 0 auto;
    }
  }

  &-above-filters {
    width: auto!important;
    background-color: white!important;
    padding: 15px 0 0!important;

    & > div {
      display: flex!important;
      justify-content: center;
      align-items: center;
      height: inherit;
      margin: 0 auto;
    }
  }

	&-right-top {
		position: fixed;
		top: 0;
		right: 0;
		width: vw_d(160);

    @include screen($media-desktop-hd){
      width: vw_d(300);
    }

    & > div > div {
      justify-content: right;
    }
	}

	&-before-loading {
		position: fixed;
    height: 100%;
    min-height: 100%;
    z-index: 100;
		right: 0;
		left: 0;
    top: 0;
		bottom: 0;
    background-color: white;

    & > div {
      display: flex!important;
      justify-content: center;
      align-items: center;
      height: inherit;
      margin: 0 auto;
    }
	}
}
