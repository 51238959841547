@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.checkbox-icon {
  cursor: pointer;
  width: vw_d(20);
  height: vw_d(20);
  margin-left: vw_d(10);

  @include screen('mobile') {
    width: vw(22);
    height: vw(22);
    margin-left: vw(10);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &.small {
    width: vw_d(15);
    height: vw_d(15);

    @include screen('mobile') {
      width: vw(15);
      height: vw(15);
    }
  }
}
