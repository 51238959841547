@-webkit-keyframes ball-scale-multiple {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0;
	}

	5% {
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes ball-scale-multiple {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 0;
	}

	5% {
		opacity: 1;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}

.preloader {
	.ball-scale-multiple {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}

	.ball-scale-multiple > div {
		position: absolute;
		top: 0;
		left: -30px;
		width: 15px;
		width: 60px;
		height: 15px;
		height: 60px;
		margin: 2px;
		margin: 0;
		background-color: #fff;
		border-radius: 100%;
		opacity: 0;
		animation: ball-scale-multiple 1s 0s linear infinite;
		animation-fill-mode: both;
	}

	.ball-scale-multiple > div:nth-child(2) {
		-webkit-animation-delay: -0.4s;
		animation-delay: -0.4s;
	}

	.ball-scale-multiple > div:nth-child(3) {
		-webkit-animation-delay: -0.2s;
		animation-delay: -0.2s;
	}
}
