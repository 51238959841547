@import 'client/styles/theme.scss';
@import 'client/styles/mixins.scss';
@import 'client/styles/functions.scss';

.iframe {
  // max-width: 740px;
  margin: 0 auto;

  &.is-real-mobile {
    max-width: 100%;
  }

  .is-iframe-filter {
    width: 100%;
    min-height: 272px;
  }
}