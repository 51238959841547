@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.checkbox-wrapper {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: vw_d(18);

  @include screen('mobile') {
    height: vw(24);
    font-size: vw(18);
    justify-content: space-between;
  }

  &.is-iframe {
    font-size: 18px;

    .checkBox-content {
      font-size: 18px;
    }

    label > div {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .checkBox-content {
    font-family: $regular-font;
    font-size: vw_d(18);
    letter-spacing: vw_d(-0.3);
    color: #414141;

    @include screen('mobile') {
      width: 100%;
      height: 100%;
      display: inline-flex;
      flex-direction: row;
      font-size: vw(18);
      font-weight: 400;
      color: black;
    }
  }

  label {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    // p {
    //   margin: 0 vw_d(10) 0 0;
    // }
    > input {
      display: none;
    }
  }
  span {
    cursor: pointer;
    text-decoration: underline;
    margin-right: vw_d(20);
    font-size: vw_d(18);
    font-family: $light-font;
    letter-spacing: vw_d(-0.3);

    @include screen('mobile') {
      font-size: vw(18);
      font-weight: 300;
    }
  }
}
