@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';
@import 'src/routes/client/styles/functions';

.intro-section {
  min-height: vw_d(350);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: vw_d(-5);

  @include screen('tablet') {
    min-height: vw(125);
  }
  @include screen('mobile') {
    min-height: vw(125);
  }

  .headerLogo {
    margin-bottom: vw(10);
    margin-top: vw(8);
    width: vw(55.9);
    height: vw(23.7);
  }

  .cover {
    width: 100%;
    min-height: inherit;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .title-wrapper {
    max-width: vw_d(488);
    margin: 0 auto;
    padding-top: vw_d(104);
    text-align: center;

    @include screen('tablet') {
      max-width: vw(285);
      padding-top: vw(18);
      padding-right: vw(18);
      margin: 0;
      text-align: right;
    }
    @include screen('mobile') {
      max-width: vw(285);
      padding-top: vw(18);
      padding-right: vw(18);
      margin: 0;
      text-align: right;
    }

    .title {
      font-family: $medium-font;
      font-size: vw_d(46);
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: -0.77px;
      padding-bottom: vw_d(4);

      @include screen('tablet') {
        text-align: right;
        font-size: vw(24);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.4px;
      }
      @include screen('mobile') {
        text-align: right;
        font-size: vw(24);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.4px;
      }
    }
    .description {
      font-family: $light-font;
      font-size: vw_d(23);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: -0.38px;

      @include screen('tablet') {
        font-family: $regular-font;
        font-size: vw(16);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.27px;
        white-space: pre-line;
      }

      @include screen('mobile') {
        font-family: $regular-font;
        font-size: vw(16);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: -0.27px;
        white-space: pre-line;
      }
    }
  }
}
