@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.filter-wrapper {
  border: solid vw_d(1) var(--borderColor);
  background-color: var(--filterSectionBg);

  .fields-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .fields-pre-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    .field {
      flex-grow: 0;
    }

    .right {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
    }

    .reset-filter-btn {
      text-decoration: underline;
      font-family: $light-font;
      color: #000;
    }
  }

  &:not(.is-iframe) {
    width: 96.7%;
    min-height: vw_d(143);
    border-radius: vw_d(24);
    padding: 0 vw_d(19) vw_d(18) vw_d(21);
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    position: relative;
    z-index: 2;
    justify-content: space-between;
  
    @include screen('mobile') {
      padding: 0 0 vw(14);
      width: vw(375);
      min-height: vw(348);
      justify-content: initial;
      border: none;
      border-radius: initial;
    }
  
    .fields-wrapper {
      height: 50%;
      margin-top: vw_d(21);
  
      @include screen('mobile') {
        flex-direction: column;
        align-items: initial;
        justify-content: initial;
        width: 100%;
        padding: 0 vw(8);
      }
  
      .fields-pre-wrapper {
        width: vw_d(634);
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
  
        @include screen('mobile') {
          display: inline-flex;
          flex-direction: column;
          align-items: initial;
          justify-content: initial;
          padding-top: vw(19);
          width: 100%;
        }
      }
  
      .field {
        flex-grow: 0;
  
        @include screen('mobile') {
          margin-bottom: vw(12);
        }
      }
  
      .fieldsPreWrapperMobile {
        margin-top: vw(23);
        display: flex;
        flex-direction: row;
      }
  
      .fieldsWrapperMobile {
        width: vw(175.5);
        height: 100%;
        margin-right: vw(8);
      }
  
      .fieldsWrapperMobileLong {
        margin-top: vw(12);
        width: vw(359);
        height: 100%;
        margin-right: vw(8);
      }
  
      .is-free-checkbox {
        margin-left: vw_d(17);
  
        @include screen('mobile') {
          label {
            html[data-theme="dark"] & {
              color: #fff;
            }
          }
        }
      }
      .mobile-field-wrap {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
  
        .field {
          width: 49%;
        }
      }
  
      .right {
        @include screen('mobile') {
          width: 100%;
          margin-bottom: vw(17);
          margin-top: vw(17);
          justify-content: space-between;
          align-items: center;
        }
      }
  
      .reset-filter-btn {
        font-size: vw_d(18);

        @include screen('mobile') {
          font-size: vw(18);
          white-space: nowrap;
  
          html[data-theme="dark"] & {
            color: #fff;
          }
        }
      }
  
      .search-button {
        width: vw_d(167);
  
        @include screen('mobile') {
          width: vw(359);
        }
      }
    }
  }

  &.is-iframe {
    display: block;
    width: 100%;
    border-radius: 24px;
    padding: 5px 31px 18px 30px;

    &.is-real-mobile {
      border-radius: 0;
      padding: 5px 8px 5px 5px;

      .fields-wrapper {
        flex-direction: column;
        align-items: initial;
        justify-content: initial;
        margin-top: 0;

        .fields-pre-wrapper {
          flex-wrap: wrap;
          margin-bottom: 12px;

          .field {
            &:nth-child(1),
            &:nth-child(2) {
              width: 49%;
              margin-bottom: 12px;
            }
          }
        }

        .right {
          margin-top: -9px;
          justify-content: space-between;
        }

        .search-button {
          width: 232px;
          margin: 9px auto 0;
        }
      }

      .iframe-header {
        padding-right: 7px;
        padding-bottom: 14px;

        .title {
          font-family: $semibold-font;
          font-size: 25px;
          line-height: 1;
          margin-left: 70px;
          padding-top: 5px;
          padding-bottom: 7px;
        }
      }

      .datepicker {
        width: 100%;
        margin-left: 0;
      }
    }
    
    .fields-wrapper {
      flex-wrap: wrap;
      margin-top: 16px;

      &.fields-wrapper-selects {
        justify-content: start;
        margin-bottom: 18px;
      }

      .fields-pre-wrapper {
        width: 100%;
        margin-bottom: 27px;
      }

      .search-button {
        width: 167px;
        height: 42px;
        border-radius: 24px;
        font-size: 23px;
      }

      .is-free-checkbox {
        font-size: 18px;
        margin-left: 60px;
      }

      .reset-filter-btn {
        font-size: 18px;
      }

      .right {
        margin-top: 4px;
        align-items: center;
      }
    }

    .iframe-header {
      position: relative;

      .logo {
        position: absolute;
        top: 7px;
        left: 0;
      }

      .title {
        font-family: $medium-font;
        font-size: 24px;
        line-height: 1;
        margin-left: 70px;
        letter-spacing: -0.4px;
        padding: 6px 0 5px;
      }

      .subtitle {
        font-size: 20px;
        line-height: 1;
      }
    }

    .datepicker {
      margin-left: 6.5%;
      width: 29%;
    }
  }
}
