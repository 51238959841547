@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
@import 'routes/client/styles/functions.scss';

.search {
  width: vw_d(254);
  height: vw_d(42);
  display: flex;
  justify-content: space-between;
  position: relative;
  scrollbar-color: #e2e2e2 transparent;
  scrollbar-width: thin!important;

  @include screen('mobile') {
    margin-top: vw(12);
    height: vw(42);
    width: vw(359);
    padding: 0;
  }

  /* width */
  ::-webkit-scrollbar {
    width: vw_d(6);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0;
    border-radius: vw_d(10);
    border: 0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e2e2e2; 
    border-radius: vw_d(10);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e2e2e2; 
  }

  &.is-iframe {
    width: 29%;
    height: 42px;
    margin-top: 0;

    &.is-real-mobile {
      width: 100%;
      margin-top: 12px;

      .suggestions-list {
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .dropdown-bg {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .search-icon-btn {
      top: 7px;
      left: 7px;
    }

    .search-icon {
      width: 28px;
      height: 28px;
    }

    input {
      font-size: 18px;
      padding: 7px 15px 7px 40px;
      border-radius: 24px;
      border: solid 2px transparent;
      color: #545353;

      &:-webkit-input-placeholder {
        color: #545353;
      }
      &::-moz-placeholder {
        color: #545353;
      }
      &::-ms-input-placeholder {
        color: #545353;
      }
      &::placeholder {
        color: #545353;
      }

      &:hover {
        border: solid 2px var(--borderColor);
      }
      &:focus {
        border: solid 2px var(--borderColor);
      }
    }

    .suggestions-list {
      width: 358px;
      height: 260px;
      position: fixed;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 16px;
      padding: 18px 6px 8px 6px;

      &-inner {
        max-height: 235px;
        padding-left: 8px;
      }

      .item {
        font-size: 18px;
        border-radius: 8px;
        padding: 7px 6px 7px;
        margin-bottom: 7px;
      }

      .close-icon {
        position: absolute;
        top: 12px;
        left: 13px;
        cursor: pointer;
      }
    }
  }

  .search-icon-btn {
    position: absolute;
    top: vw_d(7);
    left: vw_d(7);
    margin: 0;
    // transform: translate(0, -50%);

    @include screen('mobile') {
      top: vw(7);
      left: vw(8);
    }
  }

  .search-icon {
    display: block;
    height: vw_d(28);
    width: vw_d(28);

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;
    }

    @include screen('mobile') {
      height: vw(28);
      width: vw(28);
    }
  }

  .input-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  input {
    width: 100%;
    height: 100%;
    color: #000;
    font-size: vw_d(18);
    padding: vw_d(7) vw_d(15) vw_d(7) vw_d(40);
    font-family: $regular-font;
    border-radius: vw_d(24);
    outline: 0;
    box-shadow: none;
    border: solid vw_d(2) transparent;
    background-color: #fff;

    &:-webkit-input-placeholder {
      color: #545353;
      opacity: 1;

      @include screen('mobile') {
        color: #000;
      }
    }

    &:-moz-placeholder {
      color: #545353;
      opacity: 1;

      @include screen('mobile') {
        color: #000;
      }
    }

    &:-ms-input-placeholder {
      color: #545353;
      opacity: 1;

      @include screen('mobile') {
        color: #000;
      }
    }

    &:-ms-input-placeholder {
      color: #545353;
      opacity: 1;

      @include screen('mobile') {
        color: #000;
      }
    }

    &::placeholder {
      color: #545353;
      opacity: 1;

      @include screen('mobile') {
        color: #000;
      }
    }

    &:hover {
      border: solid vw_d(2) var(--borderColor);
      outline: 0;

      @include screen('mobile') {
        border: solid vw(2) var(--borderColor);
      }
    }

    &:focus {
      border: solid vw_d(2) var(--iconsColor);
      outline: 0;

      @include screen('mobile') {
        border: solid vw(2) var(--iconsColor);
      }
    }

    @include screen('mobile') {
      padding: vw(7) vw(15) vw(7) vw(40);
      color: black;
      border-radius: vw(24);
      font-size: vw(18);
      border: solid vw(2) transparent;
    }
  }

  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: vw_d(16);
    padding: vw_d(8) vw_d(4) vw_d(8) vw_d(8);
    z-index: 2;
    transform: translate(0, vw_d(8));
    box-shadow: 0 0 vw_d(9) 0 rgba(57, 110, 228, 0.1);

    @include screen('mobile') {
      border-radius: vw(16);
      padding: vw(8) vw(4) vw(8) vw(8);
      transform: translate(0, vw(8));
      box-shadow: 0 0 vw(9) 0 rgba(57, 110, 228, 0.1);
    }

    &-inner {
      max-height: vw_d(240);
      width: 100%;
      background-color: white;
      overflow-y: auto;

      @include screen('mobile') {
        max-height: vw(240);
      }
    }

    .item {
      background-color: transparent;
      border-radius: vw_d(8);
      padding: vw_d(10) vw_d(6);
      margin-left: vw_d(5);
      transition: background-color 0.2s ease-in;
      color: #000;
      font-family: $regular-font;
      font-size: vw_d(18);
      letter-spacing: vw_d(-0.3);
      cursor: pointer;

      @include screen('mobile') {
        border-radius: vw(8);
        padding: vw(10) vw(6);
        margin-left: vw(5);
        font-size: vw(18);
        letter-spacing: vw(-0.3);
      }

      span {
        font-family: $semibold-font;
      }

      &:hover {
        background-color: rgba($color: $primary, $alpha: 0.2);
      }
    }
  }
}
