@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';
@import 'src/routes/client/styles/functions';

.header {
  width: 100%;

  .headerLogo{
    display: block;

    @include screen('tablet'){
      margin: vw(12) auto vw(10);
      width: vw(56);
      height: vw(24);
      fill: #000;
       
      html[data-theme="dark"] & {
        fill: #fff;
      }
    }
    @include screen('mobile'){
      margin: vw(12) auto vw(10);
      width: vw(56);
      height: vw(24);
      fill: #000;
       
      html[data-theme="dark"] & {
        fill: #fff;
      }
    }
  }
}
