@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.button {
  position: relative;
  height: vw_d(42);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: vw_d(24);
  font-size: vw_d(23);
  letter-spacing: vw_d(-0.38);
  font-family: $regular-font;

  @include screen('desktop') {
    &:hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @include screen('mobile') {
    height: vw(42);
    font-size: vw(23);
    border-radius: vw(24);
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &.small {
    height: vw_d(34);
    font-size: vw_d(18);

    @include screen('mobile') {
      height: vw(34);
      font-size: vw(18);
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: #b0c4cb !important;
  }

  &.withIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 18px;
      height: 18px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .label {
    position: relative;
    text-align: center;
  }
}
